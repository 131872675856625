var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dn-library dn-container"},[_c('back-button',{on:{"back":function($event){return _vm.$router.push('/')}}}),_c('h1',{staticClass:"dn-library__title"},[_vm._v("Library")]),(_vm.isLibraryEmpty)?_c('p',[_vm._v("Your Library is empty. Purchase packs to add them to your personal library.")]):_vm._e(),_c('section',{staticClass:"dn-library__packs"},[(_vm.library.packs.length)?_c('carousel',{attrs:{"title":"My Packs","items":_vm.library.packs,"type":"pack","perView":6,"overflow":_vm.$vuetify.breakpoint.smAndDown,"breakpoints":{
					800: {
						perView: 2.1
					},
					1600: {
						perView:  3
					}
				}}}):_vm._e()],1),_c('section',{staticClass:"dn-library__videos"},[(_vm.library.videos.length)?_c('carousel',{attrs:{"title":"My Videos","items":_vm.library.videos,"overflow":_vm.$vuetify.breakpoint.smAndDown,"type":"video","perView":4,"breakpoints":{
					800: {
						perView: 1.3
					},
					1600: {
						perView: 2
					}
				}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }